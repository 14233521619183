/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "assets/fonts/fonts";

@import '@ionic/angular/css/ionic-swiper';

:root{
  --ion-grid-column-padding-xl: 15px;
}

* {
  font-family: 'Poppins', sans-serif;
}

.text-color-primary {
  color: var(--ion-color-primary);
}

.openingHours {
  td, th {
    padding: 5px 5px 5px 0;
  }

  br {
    display: none;
  }
}

:root {
  --swiper-navigation-color: black;
}

.li-ionic4-datePicker {
  .modal-wrapper {
    height: 75%;
    max-height: 478px;
    width: 312px;
    border-radius: 0;

    @media (min-width: 768px) {
      width: 350px;
      height: 65%;
    }
  }
}

.mat-step-text-label {
  font-size: x-small;
}

.mat-step-label {
  font-size: x-small;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: var(--ion-color-primary);
  color: white;
}

.mat-mdc-button {
  background-color: var(--ion-color-primary);
  float: right;
  color: white !important;
  margin: 10px 0 0 !important;

  padding: 0 10px;
  width: 100%;
  height: 50px;
  font-size: large;
}

.matStepperNext {
  background-color: var(--ion-color-primary);
  float: right;
  color: white;
  margin: 10px 0 0;
}

:disabled {
  background-color: lightgrey;
  color: white;
}

td, th {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
}

.mat-horizontal-content-container {
  padding: 0 10px 10px 10px !important;
}


.swiper-scrollbar {
  display: none;
}

.my-custom-modal-class {
  --backdrop-opacity: 0.4 !important;

  .modal-wrapper {
    --height: 60% !important;
    --width: 90% !important;
    position: absolute;
  }
}

ion-item {
  ion-thumbnail {
    --border-radius: 8px;
  }
}

hr {
  border-bottom: 1px solid lightgrey;
  margin: 0;
}

.app-icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  border-radius: 8px;
  padding: 30px 8px;

  background-color: var(--ion-color-primary);
  color: white;

  ion-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 20px;
  }
}

.desktop-content-padding {
  padding: 45px 0;
}

button.days-btn {
  &.last-month-day, &.next-month-day {
    display: none !important;
  }
}

ion-button.btn-secondary-outline{
  --border-radius: 0px!important;
  --border-color: var(--ion-color-secondary);
  width:100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.25;
}

@media(min-width: 1200px) {
  .container {
    max-width: 1140px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

.lunchDaySwiper {
  swiper-container {
    width: 100%;
    cursor: pointer;
    height: 100%
  }

  swiper-slide {
    text-align: center;
    font-size: 10px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 0;
  }
}

.category-slider {
  swiper-container {
    width: 100%;
    height: auto;

    swiper-wrapper{
      align-items: center;
    }

    swiper-slide {
      width: auto !important;
      padding: 15px 30px;

      > div {
        cursor: pointer;

        &.selected {
          color: var(--ion-color-primary);
          border-bottom: 2px solid var(--ion-color-primary);
        }
      }
    }
  }
}

@media(max-width: 991px) {
  .category-slider {
    swiper-container {
      swiper-slide {
        width: 100% !important;
      }
    }
  }
}
